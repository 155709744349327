@import "~@/assets/scss/variables.scss";












































































































.site-multiselect .multiselect .multiselect__option--highlight {
  background: $msi-orange;
}

.site-multiselect .multiselect .multiselect__option--selected.multiselect__option--highlight {
  background: #FF6A6A
}

.site-multiselect .multiselect .multiselect__tags .multiselect__single {
  font-size: 12px;
}

.site-multiselect .multiselect {
  font-size: 12px;
}

.site-multiselect .multiselect__spinner {
  margin-top: 2px;
}

.site-multiselect .multiselect__spinner::before {
  border-top-color: $msi-orange;
}

.site-multiselect .multiselect__spinner::after {
  border-top-color: $msi-orange;
}

.site-multiselect .multiselect__input {
  font-size: 0.9rem;
  margin-bottom: 0;
}

.site-multiselect .multiselect .multiselect__tags .multiselect__input {
  width: 75% !important;
}

.sidebar-minimized .sidebar .site-multiselect {
  display: none;
}
