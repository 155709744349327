// MSI Styles
.msi-btn {
  background-color: $msi-orange;
  color: $msi-white;
  border-color: $msi-orange;
}

.msi-btn:link,
.msi-btn:visited {
  background-color: $msi-orange;
  color: $msi-white;
}

.msi-btn:hover:enabled,
.msi-btn:active:enabled {
  background-color: $msi-orange-dark !important;
  color: $msi-white !important;
  border-color: $msi-orange-dark !important;
}

.msi-btn:disabled {
  opacity: 0.6;
}

// Here you can add other styles

$scrollbarBG: transparent;
$scrollbarThumbBG: #adadad;

.link-highlight {
    color: $msi-orange;
}

.container-fluid {
    margin-top: 15px;
}

.sidebar .nav-link {
    font-weight: 600;
    border-radius: 5px;
    margin: 1px 3px 1px 3px;
}

.sidebar .nav-link.active .nav-icon {
  color: #ffffff;
}

.sidebar-minimized .sidebar .nav-item {
    margin: 1px 0px 1px 0px;
}

.sidebar .nav-dropdown.open {
    border-radius: 5px;
    margin: 0px 3px 0px 3px;

    .active {
      color: #ffffff;

      .nav-icon{
        color: #ffffff;
      }
    }

    .nav-link:hover {
      color: #ffffff;
    }
}

.sidebar .nav-dropdown .nav-item {
  margin-left: 10px;
  margin-top: 1px;
}

.sidebar .nav-dropdown.open .nav-link {
    margin: 0;
}

.sidebar .first-nav-link {
    margin-top: 3px;
}

.sidebar-minimized .sidebar .nav-dropdown.open {
    margin: 0;
}

.switch-primary .switch-input:checked + .switch-slider {
  background: $msi-orange !important;
  border-color: $msi-orange;
}

.switch-primary .switch-input:checked + .switch-slider::before {
  border-color: $msi-orange;
  border-radius: 0.25rem;
}

.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: white;
    color: black;
    border-radius: 5px;
    padding: 5px 10px 4px;
    border: 1px solid black
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: $color;
      color: black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, .1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}

.multiselect .multiselect__tag {
  background: $msi-orange;
}

.multiselect .multiselect__tag-icon {
  background: $msi-orange;
}

.multiselect .multiselect__option--highlight {
  background: $msi-orange;
}
.multiselect .multiselect__option--selected.multiselect__option--highlight {
  background: #FF6A6A;
}

.pagination .page-item.active .page-link {
  background-color: $msi-orange;
  border-color: $msi-orange;
}

.pagination .page-link {
  color: #55595c;
}

@media only screen and (min-device-width: 736px) {
  body {
    scrollbar-width: thin;
    scrollbar-color: $scrollbarThumbBG $scrollbarBG;
  }

  ::-webkit-scrollbar,
  ::-webkit-scrollbar-thumb {
    width: 15px;
    background-clip: padding-box;
    border-radius: 13px;
    border: 4px solid transparent;
  }

  ::-webkit-scrollbar-track {
    background: $scrollbarBG;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $scrollbarThumbBG;
  }
}

// Fixes drop down bug when updating to bootstrap-vue to 2.2.0
.app-header .dropdown-item {
  min-width: 0 !important;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: $msi-orange;
  border-color: $msi-orange;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 $msi-orange;
          box-shadow: -10px 0 0 $msi-orange;
}

.flatpickr-day.week.selected {
  -webkit-box-shadow: -5px 0 0 $msi-orange, 5px 0 0 $msi-orange;
          box-shadow: -5px 0 0 $msi-orange, 5px 0 0 $msi-orange;
}

.flatpickr-monthSelect-month.selected {
  background-color: $msi-orange;
}

.msi-date {
  height: 45px;
  color: #35495e;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  padding: 8px 8px 8px 8px;
  font-size: 14px;
  width: 100%;
}

.msi-date:focus {
  outline: none;
}

.msi-date::placeholder {
  color: #adadad;
}

.msi-date-sm {
  height: 35px;
  padding: 4px 8px 4px 8px;
  font-size: 14px;
}
