@import "~@/assets/scss/variables.scss";
















































































































































































































































.feedback-form {
  font-size: 0.8rem;
}

.icon {
  color: $msi-gray-400;
  cursor: pointer;
}

.icon:hover {
  color: $msi-gray-600;
}

.preface {
  font-weight: 500;
  font-size: 0.85rem;
}

.warning {
  font-weight: 500;
  font-size: 0.8rem;
}

.snapshot-title {
  font-size: 1rem;
  font-weight: 500;
}

.snapshot-file {
  background-color: $msi-gray-100;
}

.snapshot-name {
  word-break: break-word;
  flex-basis: 50%;
}

.snapshot-progress {
  max-width: 200px;
}

.snapshot-success {
  font-size: 0.7rem;
  font-weight: 500;
  color: $msi-success;
  max-width: 200px;
}

.snapshot-failed {
  font-size: 0.7rem;
  font-weight: 500;
  color: $msi-danger;
  max-width: 200px;
}

.failed-snapshots {
  color: $msi-danger;
  font-weight: 500;
}

.attach-image {
  cursor: pointer;
}
