@import "~@/assets/scss/variables.scss";
































$simple-line-font-path: '~simple-line-icons/fonts/';
@import '~simple-line-icons/scss/simple-line-icons.scss';
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import 'assets/scss/style';
@import '~vue-multiselect/dist/vue-multiselect.min.css';
@import '~flatpickr/dist/flatpickr.css';
@import '~flatpickr/dist/plugins/monthSelect/style.css';
