@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    @if $breakpoint == "lg" or $breakpoint == 'xl' {
      .main, .app-footer {
        margin-left: $sidebar-width;
      }

      .sidebar-fixed {
        .main, .app-footer {
          margin-left: 0;
        }
      }
    }
  }
}
