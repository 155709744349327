@import "~@/assets/scss/variables.scss";


























































































































































.feedback:hover {
  cursor: pointer;
}

.feedback:hover {
  color: $msi-orange-dark;
}
