@import "~@/assets/scss/variables.scss";


















































.dropdown-icon {
  color: rgba(32, 32, 32, 0.616);
}

.dropdown-item:active .dropdown-icon {
  color: $msi-white;
}
